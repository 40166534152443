<template>
  <div class="medications">
    <TopBlock
      :title="'Препараты AstraZeneca'"
      :description="'Являясь одной из ведущих фармацевтических компаний в мире, в первую очередь мы ориентированы на превращение новейших идей в инновационные и эффективные лекарственные препараты, которые играют ключевую роль в различных областях медицины.'"
      :images="[
        {
          src: require('@/assets/img/medications_mobile.jpg'),
          media: '(max-width: 767px)',
        },
        {
          src: require('@/assets/img/medications_tablet.jpg'),
          media: '(max-width: 1220px)',
        },
        {
          src: require('@/assets/img/medications.jpg'),
          media: '(max-width: 1919px)',
        },
        {
          src: require('@/assets/img/medications_1920.jpg'),
          media: '(max-width: 2559px)',
        },
        {
          src: require('@/assets/img/medications_2560.jpg'),
          media: '(max-width: 3839px)',
        },
        {
          src: require('@/assets/img/medications_3840.jpg'),
          media: '(max-width: 999999999px)',
        },
      ]"
    />
    <Breadcrumbs :pages="[{ name: 'Препараты' }]" />
    <div class="filter">
      <div class="content">
        <div class="filter__row d-none d-md-grid" data-id="filterElem" v-if="!$root.isMobile">
          <Select
            :taggable="false"
            :push-tags="false"
            class="filter__item"
            v-model="therapeutic_areas"
            :items="therapeutic_areas_list"
            :placeholder="'Терапевтические области'"
            :multiple="true"
            @change="medicationChange(therapeutic_areas_YM)"
          />
          <Select
            class="filter__item"
            v-model="disease"
            :items="disease_list"
            :placeholder="'Нозология'"
          />
        </div>
        <div class="filter__opener d-md-none"  v-if="$root.isMobile" data-id="filterElem" @click="openFilter()">
          <span class="mr-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 18.95V17.45H9.175V18.95H3ZM3 6.55V5.05H13.325V6.55H3ZM10.675 21V15.375H12.175V17.45H21V18.95H12.175V21H10.675ZM7.675 14.8V12.75H3V11.25H7.675V9.15H9.175V14.8H7.675ZM10.675 12.75V11.25H21V12.75H10.675ZM14.825 8.625V3H16.325V5.05H21V6.55H16.325V8.625H14.825Z"
                fill="#3C4242"
              />
            </svg>
          </span>
          Отфильтровать
        </div>
        <div class="filter-mobile d-md-none" v-if="filterOpened">
          <div
            @click="closeFilter()"
            class="filter-mobile__back button button_empty-pink button_small"
          >
            <span class="mr-2"
              ><svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 1L1 6L6 11"
                  stroke="currentColor"
                  stroke-linecap="square"
                ></path></svg
            ></span>
            Назад
          </div>
          <div class="filter-mobile__title">{{ filterTitle }}</div>
          <div class="filter-mobile__items" v-if="selectedFilter === null">
            <div
              class="filter-mobile__item"
              @click="selectFilter(0, 'Терапевтические области')"
            >
              Терапевтические области
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
            <div
              class="filter-mobile__item"
              @click="selectFilter(1, 'Нозология')"
            >
              Нозология
              <div class="filter-mobile__item-icon">
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="#3C4242"
                    stroke-linecap="square"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 0">
            <Checkbox
              v-for="(check, ind) in therapeutic_areas_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="therapeutic_areas"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="medicationChange(therapeutic_areas_YM)"
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('therapeutic_areas')"
              >
                Очистить
              </div>
            </div>
          </div>
          <div class="filter-mobile__filter" v-if="selectedFilter === 1">
            <Checkbox
              v-for="(check, ind) in disease_list"
              :key="ind"
              :label="check.label"
              :inputValue="check.value"
              v-model="disease"
              class="mb-6"
            />
            <div class="filter-mobile__buttons">
              <div
                class="filter-mobile__accept button button_pink mb-3"
                @click="closeFilter()"
              >
                Применить
              </div>
              <div
                class="filter-mobile__accept button button_empty-pink"
                @click="clearFilter('disease')"
              >
                Очистить
              </div>
            </div>
          </div>
        </div>
        <div
          class="filter__current-filter"
          v-if="currentFilter && currentFilter.length"
        >
          <div
            class="filter__current-filter-item"
            v-for="(item, ind) in currentFilter"
            :key="ind"
          >
            <span v-html="item.label"></span>
            <span
              @click="removeFilterItem(item.type, item.value)"
              class="ml-2 filter-item__remove"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2.00012C6.49 2.00012 2 6.49012 2 12.0001C2 17.5101 6.49 22.0001 12 22.0001C17.51 22.0001 22 17.5101 22 12.0001C22 6.49012 17.51 2.00012 12 2.00012ZM15.36 14.3001C15.65 14.5901 15.65 15.0701 15.36 15.3601C15.21 15.5101 15.02 15.5801 14.83 15.5801C14.64 15.5801 14.45 15.5101 14.3 15.3601L12 13.0601L9.7 15.3601C9.55 15.5101 9.36 15.5801 9.17 15.5801C8.98 15.5801 8.79 15.5101 8.64 15.3601C8.35 15.0701 8.35 14.5901 8.64 14.3001L10.94 12.0001L8.64 9.70012C8.35 9.41012 8.35 8.93012 8.64 8.64012C8.93 8.35012 9.41 8.35012 9.7 8.64012L12 10.9401L14.3 8.64012C14.59 8.35012 15.07 8.35012 15.36 8.64012C15.65 8.93012 15.65 9.41012 15.36 9.70012L13.06 12.0001L15.36 14.3001Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </div>
          <div
            class="filter__clear button button_empty-pink button_small"
            @click="clearFilter()"
          >
            Сбросить
            <span class="ml-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.5 3.50012L12.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
                <path
                  d="M12.5 3.50012L3.5 12.5001"
                  stroke="currentColor"
                  stroke-linecap="square"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="medications__list" v-if="Medications && Medications.length">
        <Medication
          v-for="drug in Medications"
          :key="drug.id"
          :item="drug"
          @favorite="
            favorite(drug.favorite ? 'DELETE' : 'POST', drug.slug, filter)
          "
          @watchLater="
            watchLater(drug.watch_later ? 'DELETE' : 'POST', drug.slug, filter)
          "
          @toMediDetail="toMediDetail"
        />
      </div>
      <div v-else-if="!Medications" class="preloader detail-page__preloader">
        <Preloader class="" />
      </div>
      <div v-else class="medications__empty">
        По выбранному фильтру нет материалов
      </div>
      <div
        class="medications__up button button_empty-pink"
        v-if="Medications && Medications.length"
        @click="toTop()"
      >
        К началу страницы
        <span class="ml-2">
          <svg
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Select from "@/components/form-elements/Select.vue";
import Breadcrumbs from "@/components/pageComponents/Breadcrumbs.vue";
import TopBlock from "@/components/pageComponents/TopBlock.vue";

import Checkbox from "@/components/form-elements/Checkbox.vue";
import Medication from "../components/pageComponents/Medication.vue";

import filter from "@/mixins/filter";
import { mapActions, mapGetters } from "vuex";
import Preloader from "@/components/Preloader.vue";
import { bus } from "@/main";

export default {
  metaInfo: {
    title: "Препараты AstraZeneca",
  },
  mixins: [filter],
  name: "Medications",
  components: {
    TopBlock,
    Breadcrumbs,
    Select,
    Checkbox,
    Medication,
    Preloader,
  },
  data: () => ({
    filterTitle: "Фильтр",
    filterOpened: false,
    items: [],

    index: null,
  }),
  computed: {
    ...mapGetters(["user", "NosologysFilter", "Medications", "DiseaseFilter"]),
    therapeutic_areas_list() {
      return this.NosologysFilter.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
    },
    therapeutic_areas_YM() {
      let arr = [];
      this.therapeutic_areas.forEach((i) => {
        let find = this.therapeutic_areas_list.find((el) => el.value === i);
        if (find) {
          arr.push(find.label);
        }
      });
      return arr;
    },
    disease_list() {
      return this.DiseaseFilter.map((el) => ({
        label: el.title,
        value: el.slug || el.id,
      }));
    },
  },
  methods: {
    ...mapActions([
      "fetchNosologysFilter",
      "fetchMedications",
      "fetchMedicationFavorite",
      "fetchMedicationWatchLater",
    ]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    watchLater(method, slug, filter) {
      this.fetchMedicationWatchLater({
        method: method,
        slug: slug,
        filter: filter,
      });
    },
    favorite(method, slug, filter) {
      this.fetchMedicationFavorite({
        method: method,
        slug: slug,
        filter: filter,
      });
    },
    medicationChange(value) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "medications page filter use", {
          "medications page filter use": {
            "used filter name": `${value.join(",")}`,
            ...this.$root.ymFields,
          },
        });
      }
      this.closeFilter();
    },
    toMediDetail(name) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "medication page click", {
          "medication page click": {
            "medications page name": name,
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  async mounted() {
    if (
      this.user &&
      Object.keys(this.user).length &&
      typeof this.user === "object" &&
      ((this.user.flags && !this.user.flags.includes("filter")) ||
        !this.user.flags)
    ) {
        bus.$emit("startFilterHelp");
    }
     
    await this.fetchNosologysFilter();
    await this.fetchMedications(this.filter);
  },
  watch: {
    therapeutic_areas() {
      this.buildQuery();
    },
    disease() {
      this.buildQuery();
    },
    filter() {
      this.fetchMedications(this.filter);
    },
    Medications() {
      if (localStorage.getItem("medicationBlock") && this.Medications.length) {
        let ind = this.Medications.findIndex(
          (el) => el.slug == localStorage.getItem("medicationBlock")
        );
        if (ind > -1) {
          setTimeout(() => {
            if (
              document.querySelector(
                `#MedicationDetail_${this.Medications[ind].slug}`
              )
            ) {
              window.scrollTo({
                behavior: "smooth",
                top:
                  document.querySelector(
                    `#MedicationDetail_${this.Medications[ind].slug}`
                  ).offsetTop + 150,
              });
              localStorage.removeItem("medicationBlock");
            }
          }, 500);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.medications {
  &__empty {
    text-align: center;
    padding: 16px 0;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
  }
  &__list {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 24px;
    }
  }

  &__up {
    margin: 40px auto 0;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}
.filter {
  margin-top: 64px;
  @media screen and (max-width: 1220px) {
    margin-top: 40px;
  }
  &__row {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }
  }

  &__item {
    max-width: none;
  }

  &__current-filter-item {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    margin-right: 18px;

    & svg {
      color: #d9dada;
      transition: 0.3s;
    }

    &:hover {
      & svg {
        color: #830051;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__current-filter {
    margin-top: 18px;
    padding: 0 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  &__opener {
    padding: 0 19px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;
    background: #f8f8f8;
    border: 1px solid #d8dada;
    border-radius: 999px;
  }

  &__clear {
    margin-bottom: 14px;
  }

  &-mobile {
    padding: 32px 16px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;

    &__buttons {
      position: absolute;
      bottom: 60px;
      width: 100%;
      left: 0;
      padding: 0 16px;
    }

    &__filter {
      max-height: calc(100vh - 310px);
      overflow-y: auto;
    }

    &__back {
      margin-bottom: 36px;
      width: 80px;
      height: 28px;
    }

    &__title {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__item {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #3c4242;
    }
  }
}

.filter-item__remove {
  cursor: pointer;
}
</style>